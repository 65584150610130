var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pap-popup" },
    [
      _vm.isVegaPopupEnable
        ? [
            _c("div", { staticClass: "pap-popup-inputs" }, [
              _c("div", { staticClass: "language-selection" }, [
                _c("div", { staticClass: "language-selection-text" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("vue.selectLanguageText")) +
                      "\n        "
                  ),
                ]),
                _c(
                  "div",
                  [
                    _c("v-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass:
                        "language-selection-select mb-4 md:mb-0 w-full",
                      staticStyle: {
                        display: "inline-block",
                        "max-width": "520px",
                      },
                      attrs: {
                        name: "language-select",
                        options: _vm.webconnectSupportedLanguages,
                        placeholder: _vm.$t("vue.selectLanguage"),
                        label: "lnFullText",
                        clearable: false,
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                        "validate-on": "blur",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "option",
                            fn: function (option) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(option.lnFullText) +
                                      "\n              "
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1992402289
                      ),
                      model: {
                        value: _vm.selectedLanguage,
                        callback: function ($$v) {
                          _vm.selectedLanguage = $$v
                        },
                        expression: "selectedLanguage",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "flex items-center" }, [
                _c(
                  "span",
                  { on: { click: _vm.toggleActivateOnlyB2B } },
                  [
                    _c("toggle-switch-icon", {
                      attrs: {
                        enabled: _vm.isActivateOnlyB2B,
                        width: 40,
                        height: 40,
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "ml-2" }, [
                  _vm._v(_vm._s(_vm.$t("vega.popup.activateForB2BOnly"))),
                ]),
              ]),
              _c("div", { staticClass: "flex items-center" }, [
                _c(
                  "span",
                  { on: { click: _vm.toggleEnableVegaPopup } },
                  [
                    _c("toggle-switch-icon", {
                      attrs: {
                        enabled: _vm.isVegaPopupEnable,
                        width: 40,
                        height: 40,
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "ml-2" }, [_vm._v("Pop-Up")]),
              ]),
              _c("div", { staticClass: "flex items-center" }, [
                _c(
                  "span",
                  { on: { click: _vm.toggleShowVegaOnlyWhenAgentsAreOnline } },
                  [
                    _c("toggle-switch-icon", {
                      attrs: {
                        enabled: _vm.showVegaOnlyWhenAgentsOnline,
                        width: 40,
                        height: 40,
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "ml-2" }, [
                  _vm._v(
                    _vm._s(_vm.$t("vega.popup.showVegaOnlyWehnAgentsOnline"))
                  ),
                ]),
              ]),
              _c("div", { staticClass: "pap-popup-inputs--label" }, [
                _vm._v(_vm._s(_vm.$t("vega.popup.addPopup"))),
              ]),
              _c("div", { staticClass: "pap-popup-inputs--text" }, [
                !_vm.canUpdateTextFields
                  ? _c("div", { staticClass: "w-full" }, [_vm._v("English")])
                  : _vm._e(),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.popupText,
                      expression: "popupText",
                    },
                  ],
                  staticClass: "pap-popup-inputs--text-input",
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t("vega.popup.headline"),
                  },
                  domProps: { value: _vm.popupText },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.popupText = $event.target.value
                    },
                  },
                }),
                !_vm.canUpdateTextFields
                  ? _c("div", { staticClass: "w-full mt-2" }, [
                      _vm._v(
                        _vm._s(this.selectedLanguageForWebConnect.lnFullText)
                      ),
                    ])
                  : _vm._e(),
                !_vm.canUpdateTextFields
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.tWebConnectVegaText,
                          expression: "tWebConnectVegaText",
                        },
                      ],
                      staticClass: "pap-popup-inputs--text-input",
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t("vega.popup.headline"),
                      },
                      domProps: { value: _vm.tWebConnectVegaText },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.tWebConnectVegaText = $event.target.value
                        },
                      },
                    })
                  : _vm._e(),
              ]),
              _c(
                "div",
                { staticClass: "pap-popup-inputs--textarea" },
                [
                  !_vm.canUpdateTextFields
                    ? _c("div", { staticClass: "w-full" }, [_vm._v("English")])
                    : _vm._e(),
                  _c("quill-editor", {
                    ref: "quillEditorA",
                    attrs: { options: _vm.editorOption },
                    on: { "text-change": _vm.onTextChange },
                    model: {
                      value: _vm.popupMessage,
                      callback: function ($$v) {
                        _vm.popupMessage = $$v
                      },
                      expression: "popupMessage",
                    },
                  }),
                  !_vm.canUpdateTextFields
                    ? _c("div", { staticClass: "w-full mt-2" }, [
                        _vm._v(
                          _vm._s(this.selectedLanguageForWebConnect.lnFullText)
                        ),
                      ])
                    : _vm._e(),
                  !_vm.canUpdateTextFields
                    ? _c("quill-editor", {
                        ref: "quillEditorATranslation",
                        attrs: { options: _vm.editorOption },
                        on: { "text-change": _vm.onTextChangeTranslation },
                        model: {
                          value: _vm.tWebConnectVegaMessage,
                          callback: function ($$v) {
                            _vm.tWebConnectVegaMessage = $$v
                          },
                          expression: "tWebConnectVegaMessage",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("div", { staticClass: "pap-popup-inputs--label mt-4" }, [
                _vm._v(_vm._s(_vm.$t("vega.popup.popupPosition"))),
              ]),
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c(
                    "vs-radio",
                    {
                      staticClass: "mt-2 mr-4",
                      attrs: {
                        "vs-value": _vm.VEGA_POPUP_POSITION.CENTERED.value,
                        "vs-name": "widget-position",
                      },
                      model: {
                        value: _vm.popupPosition,
                        callback: function ($$v) {
                          _vm.popupPosition = $$v
                        },
                        expression: "popupPosition",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.VEGA_POPUP_POSITION.CENTERED.text) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "vs-radio",
                    {
                      staticClass: "mt-2 mr-4",
                      attrs: {
                        "vs-value":
                          _vm.VEGA_POPUP_POSITION.VISITOR_MOUSE_POSITION.value,
                        "vs-name": "widget-position",
                      },
                      model: {
                        value: _vm.popupPosition,
                        callback: function ($$v) {
                          _vm.popupPosition = $$v
                        },
                        expression: "popupPosition",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.VEGA_POPUP_POSITION.VISITOR_MOUSE_POSITION.text
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "pap-popup-inputs--label mt-4" }, [
                _vm._v(_vm._s(_vm.$t("vega.popup.vegaLockingTime"))),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.vegaLockingTime,
                    expression: "vegaLockingTime",
                  },
                ],
                staticClass: "pap-popup-inputs--text-input",
                attrs: { type: "number" },
                domProps: { value: _vm.vegaLockingTime },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.vegaLockingTime = $event.target.value
                  },
                },
              }),
            ]),
            _c(
              "div",
              { staticClass: "pap-popup-preview" },
              [
                _c("div", { staticClass: "pap-popup-inputs--label" }, [
                  _vm._v(_vm._s(_vm.$t("vega.choose.previewLabel"))),
                ]),
                _c("VegaPreview", {
                  attrs: { text: _vm.popupText, message: _vm.popupMessage },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      !_vm.isVegaPopupEnable
        ? [
            _c("div", { staticClass: "pap-popup__preview-settings" }, [
              _c("div", { staticClass: "flex items-center" }, [
                _c(
                  "span",
                  { on: { click: _vm.toggleActivateOnlyB2B } },
                  [
                    _c("toggle-switch-icon", {
                      attrs: {
                        enabled: _vm.isActivateOnlyB2B,
                        width: 50,
                        height: 50,
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "ml-2" }, [
                  _vm._v(_vm._s(_vm.$t("vega.popup.activateForB2BOnly"))),
                ]),
              ]),
              _c("div", { staticClass: "flex items-center" }, [
                _c(
                  "span",
                  { on: { click: _vm.toggleEnableVegaPopup } },
                  [
                    _c("toggle-switch-icon", {
                      attrs: {
                        enabled: _vm.isVegaPopupEnable,
                        width: 50,
                        height: 50,
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "ml-2" }, [_vm._v("Pop-Up")]),
              ]),
              _c("div", { staticClass: "pap-popup__preview-settings__info" }, [
                _c("img", {
                  staticClass: "pap-popup__preview-settings__info__action-img",
                  attrs: {
                    src: require("@/assets/images/vega/action-cursor-icon.svg"),
                  },
                }),
                _c("div", { staticClass: "flex items-center" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mr-3 pap-popup__preview-settings__info__title",
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("vega.choose.vegaAnalysesAreActive"))
                      ),
                    ]
                  ),
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/vega/blue-check-filled-icon.svg"),
                    },
                  }),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "pap-popup__preview-settings__info__description",
                  },
                  [_vm._v(_vm._s(_vm.$t("vega.choose.vegaActivatePopupText")))]
                ),
                _c(
                  "div",
                  { staticClass: "pap-popup__preview-settings__info__list" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "pap-popup__preview-settings__info__list__item",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("vega.choose.vegaActivatePopupListItem_1")
                          )
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "pap-popup__preview-settings__info__list__item",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("vega.choose.vegaActivatePopupListItem_2")
                          )
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._m(0),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("@/assets/images/vega/vega-analyses-preview.png"),
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }