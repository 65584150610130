var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vap-preview" }, [
    _c(
      "div",
      { staticClass: "vap-preview--bubble" },
      [_c("VegaBubbleIcon")],
      1
    ),
    _c("div", { staticClass: "vap-preview-box" }, [
      _c(
        "div",
        { staticClass: "vap-preview-box--close" },
        [
          _c("close-icon", {
            attrs: { width: 15, height: 15, color: _vm.step2ButtonForeColor },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "vap-preview-box--headline" }, [
        _vm._v(_vm._s(_vm.text)),
      ]),
      _c("div", {
        staticClass: "vap-preview-box--message",
        domProps: { innerHTML: _vm._s(_vm.message) },
      }),
      _vm.dialog
        ? _c("div", { staticClass: "vap-preview-box--action" }, [
            !_vm.dialog.communicationOptions ||
            _vm.dialog.communicationOptions.chat
              ? _c(
                  "div",
                  {
                    staticClass: "vap-preview-box--action--btn",
                    style: _vm.step2ButtonColor,
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("VegaChatIcon", {
                          attrs: {
                            width: 15,
                            height: 15,
                            color: _vm.dialog.step2IconColor,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { style: _vm.step2IconColor }, [
                      _vm._v(_vm._s(_vm.$tp("buttonAndHints.chat"))),
                    ]),
                  ]
                )
              : _vm._e(),
            !_vm.dialog.communicationOptions ||
            _vm.dialog.communicationOptions.audio
              ? _c(
                  "div",
                  {
                    staticClass: "vap-preview-box--action--btn",
                    style: _vm.step2ButtonColor,
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("VegaPhoneIcon", {
                          attrs: {
                            width: 15,
                            height: 14,
                            color: _vm.dialog.step2IconColor,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { style: _vm.step2IconColor }, [
                      _vm._v(_vm._s(_vm.$tp("buttonAndHints.phone"))),
                    ]),
                  ]
                )
              : _vm._e(),
            !_vm.dialog.communicationOptions ||
            _vm.dialog.communicationOptions.video
              ? _c(
                  "div",
                  {
                    staticClass: "vap-preview-box--action--btn",
                    style: _vm.step2ButtonColor,
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("VegaVideoIcon", {
                          attrs: {
                            width: 17,
                            height: 12,
                            color: _vm.dialog.step2IconColor,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { style: _vm.step2IconColor }, [
                      _vm._v(_vm._s(_vm.$tp("buttonAndHints.video"))),
                    ]),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }