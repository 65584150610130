<template>
  <div class="pap-popup">
    <template v-if="isVegaPopupEnable">
      <div class="pap-popup-inputs">
        <div class="language-selection">
          <div class="language-selection-text">
            {{ $t('vue.selectLanguageText') }}
          </div>
          <div>
            <v-select
              name="language-select"
              class="language-selection-select mb-4 md:mb-0 w-full"
              style="display: inline-block; max-width: 520px"
              :options="webconnectSupportedLanguages"
              :placeholder="$t('vue.selectLanguage')"
              label="lnFullText"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="selectedLanguage"
              v-validate="'required'"
              validate-on="blur"
            >
              <template v-slot:option="option">
                <div>
                  {{ option.lnFullText }}
                </div>
              </template>
            </v-select>
          </div>
        </div>
        <div class="flex items-center">
          <span @click="toggleActivateOnlyB2B">
            <toggle-switch-icon :enabled="isActivateOnlyB2B" :width="40" :height="40" />
          </span>

          <div class="ml-2">{{ $t('vega.popup.activateForB2BOnly') }}</div>
        </div>

        <div class="flex items-center">
          <span @click="toggleEnableVegaPopup">
            <toggle-switch-icon :enabled="isVegaPopupEnable" :width="40" :height="40" />
          </span>

          <div class="ml-2">Pop-Up</div>
        </div>

        <div class="flex items-center">
          <span @click="toggleShowVegaOnlyWhenAgentsAreOnline">
            <toggle-switch-icon :enabled="showVegaOnlyWhenAgentsOnline" :width="40" :height="40" />
          </span>

          <div class="ml-2">{{ $t('vega.popup.showVegaOnlyWehnAgentsOnline') }}</div>
        </div>

        <div class="pap-popup-inputs--label">{{ $t('vega.popup.addPopup') }}</div>
        <div class="pap-popup-inputs--text">
          <div v-if="!canUpdateTextFields" class="w-full">English</div>
          <input type="text" v-model="popupText" class="pap-popup-inputs--text-input" :placeholder="$t('vega.popup.headline')" />
          <div v-if="!canUpdateTextFields" class="w-full mt-2">{{ this.selectedLanguageForWebConnect.lnFullText }}</div>
          <input
            v-if="!canUpdateTextFields"
            type="text"
            v-model="tWebConnectVegaText"
            class="pap-popup-inputs--text-input"
            :placeholder="$t('vega.popup.headline')"
          />
        </div>
        <div class="pap-popup-inputs--textarea">
          <div v-if="!canUpdateTextFields" class="w-full">English</div>
          <quill-editor v-model="popupMessage" ref="quillEditorA" :options="editorOption" @text-change="onTextChange"> </quill-editor>
          <div v-if="!canUpdateTextFields" class="w-full mt-2">{{ this.selectedLanguageForWebConnect.lnFullText }}</div>
          <quill-editor
            v-if="!canUpdateTextFields"
            v-model="tWebConnectVegaMessage"
            ref="quillEditorATranslation"
            :options="editorOption"
            @text-change="onTextChangeTranslation"
          >
          </quill-editor>
        </div>
        <div class="pap-popup-inputs--label mt-4">{{ $t('vega.popup.popupPosition') }}</div>
        <div class="flex">
          <vs-radio v-model="popupPosition" :vs-value="VEGA_POPUP_POSITION.CENTERED.value" class="mt-2 mr-4" vs-name="widget-position">
            {{ VEGA_POPUP_POSITION.CENTERED.text }}
          </vs-radio>
          <vs-radio v-model="popupPosition" :vs-value="VEGA_POPUP_POSITION.VISITOR_MOUSE_POSITION.value" class="mt-2 mr-4" vs-name="widget-position">
            {{ VEGA_POPUP_POSITION.VISITOR_MOUSE_POSITION.text }}
          </vs-radio>
        </div>

        <div class="pap-popup-inputs--label mt-4">{{ $t('vega.popup.vegaLockingTime') }}</div>
        <input type="number" v-model="vegaLockingTime" class="pap-popup-inputs--text-input" />
      </div>
      <div class="pap-popup-preview">
        <div class="pap-popup-inputs--label">{{ $t('vega.choose.previewLabel') }}</div>
        <VegaPreview :text="popupText" :message="popupMessage" />
      </div>
    </template>

    <template v-if="!isVegaPopupEnable">
      <div class="pap-popup__preview-settings">
        <div class="flex items-center">
          <span @click="toggleActivateOnlyB2B">
            <toggle-switch-icon :enabled="isActivateOnlyB2B" :width="50" :height="50" />
          </span>

          <div class="ml-2">{{ $t('vega.popup.activateForB2BOnly') }}</div>
        </div>

        <div class="flex items-center">
          <span @click="toggleEnableVegaPopup">
            <toggle-switch-icon :enabled="isVegaPopupEnable" :width="50" :height="50" />
          </span>

          <div class="ml-2">Pop-Up</div>
        </div>

        <div class="pap-popup__preview-settings__info">
          <img src="@/assets/images/vega/action-cursor-icon.svg" class="pap-popup__preview-settings__info__action-img" />

          <div class="flex items-center">
            <div class="mr-3 pap-popup__preview-settings__info__title">{{ $t('vega.choose.vegaAnalysesAreActive') }}</div>
            <img src="@/assets/images/vega/blue-check-filled-icon.svg" />
          </div>

          <div class="pap-popup__preview-settings__info__description">{{ $t('vega.choose.vegaActivatePopupText') }}</div>

          <div class="pap-popup__preview-settings__info__list">
            <div class="pap-popup__preview-settings__info__list__item">{{ $t('vega.choose.vegaActivatePopupListItem_1') }}</div>
            <div class="pap-popup__preview-settings__info__list__item">{{ $t('vega.choose.vegaActivatePopupListItem_2') }}</div>
          </div>
        </div>
      </div>

      <div>
        <img src="@/assets/images/vega/vega-analyses-preview.png" />
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import vSelect from 'vue-select'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

import VegaPreview from '../components/VegaPreview.vue'
import ToggleSwitchIcon from '@/components/icons/ToggleSwitchIcon.vue'

const VEGA_POPUP_POSITION = Object.freeze({
  CENTERED: { text: 'Centered', value: 'centered' },
  VISITOR_MOUSE_POSITION: { text: 'Visitor Mouse Position', value: 'visitor-mouse-position' }
})

export default {
  name: 'VegaStepPopupText',
  components: {
    vSelect,
    quillEditor,
    VegaPreview,
    ToggleSwitchIcon
  },
  data() {
    return {
      VEGA_POPUP_POSITION,
      popupText: '',
      popupMessage: '',
      isActivateOnlyB2B: false,
      isVegaPopupEnable: true,
      showVegaOnlyWhenAgentsOnline: false,
      popupPosition: VEGA_POPUP_POSITION.CENTERED.value,
      vegaLockingTime: 5,
      editorOption: {
        modules: {
          toolbar: {
            container: [['bold', 'italic', 'underline', { size: ['small', false, 'large', 'huge'] }]]
          }
        }
      },
      selectedLanguage: { code: 'en', lnFullText: 'English', lnText: 'ENG' },
      tWebConnectVegaText: '',
      tWebConnectVegaMessage: ''
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      dialog: 'selectedVegaWidget',
      selectedVegaWidgetId: 'selectedVegaWidgetId',
      selectedLanguageForWebConnect: 'widgetTranslation/selectedLanguageForWebConnect',
      hasWidgetTranslationUpdated: 'widgetTranslation/hasWidgetTranslationUpdated',
      selectedWidgetTranslation: 'widgetTranslation/selectedWidgetTranslation',
      hasLoadedWidgetTranslation: 'widgetTranslation/hasLoadedWidgetTranslation'
    }),
    webconnectSupportedLanguages() {
      const languages = [...this.languages]
      languages.push({ code: 'en', lnFullText: 'English', lnText: 'ENG' })
      return languages
    },
    canUpdateTextFields() {
      if (!this.selectedLanguageForWebConnect || this.selectedLanguageForWebConnect.code === 'en') return true

      return false
    }
  },
  created() {
    this.editorOption.placeholder = this.$i18n.t('vega.popup.message')
    this.popupText = this.$i18n.t('webConnect.vegaText', 'en')
    this.popupMessage = this.$i18n.t('webConnect.vegaMessage', 'en')
    this.popupPosition = VEGA_POPUP_POSITION.CENTERED.value
    this.vegaLockingTime = 5
  },
  mounted() {
    if (this.dialog && this.dialog.vega) {
      this.popupText = this.dialog.vega.text || this.$i18n.t('webConnect.vegaText')
      this.popupMessage = this.dialog.vega.message || this.$i18n.t('webConnect.vegaMessage')
      this.popupPosition = this.dialog.vega.position || VEGA_POPUP_POSITION.CENTERED.value
      if (typeof this.dialog.vega.vegaLockingTime === 'number') {
        this.vegaLockingTime = this.dialog.vega.vegaLockingTime
      }

      if (this.dialog.vega.b2bOnly === true) {
        this.isActivateOnlyB2B = true
      }

      if (this.dialog.vega.isVegaPopupEnable === false) {
        this.isVegaPopupEnable = false
      }
      if (typeof this.dialog.vega.showVegaOnlyWhenAgentsOnline !== 'undefined') {
        this.showVegaOnlyWhenAgentsOnline = this.dialog.vega.showVegaOnlyWhenAgentsOnline
      }
    }

    if (
      this.selectedLanguage &&
      this.selectedLanguage.code &&
      this.selectedLanguageForWebConnect &&
      this.selectedLanguageForWebConnect.code &&
      this.selectedLanguage.code === this.selectedLanguageForWebConnect.code
    ) {
      this.getTranslation()
    } else {
      this.selectedLanguage = this.selectedLanguageForWebConnect
    }
  },
  methods: {
    ...mapMutations({
      setVegaPopupText: 'SET_VEGA_POPUP_TEXT',
      setVegaPopupMessage: 'SET_VEGA_POPUP_MESSAGE',
      setVegaPopupPosition: 'SET_VEGA_POPUP_POSITION',
      setVegaLockingTime: 'SET_VEGA_LOCKING_TIME'
    }),
    ...mapActions({
      setHasWidgetTranslationUpdated: 'widgetTranslation/setHasWidgetTranslationUpdated',
      setWidgetTranslation: 'widgetTranslation/setWidgetTranslation',
      setLoadedWidgetTranslation: 'widgetTranslation/setLoadedWidgetTranslation',
      setDefaultWidgetTranslation: 'widgetTranslation/setDefaultWidgetTranslation',
      setSelectedLanguageForWebConnect: 'widgetTranslation/setSelectedLanguageForWebConnect',
      updateWidgetTranslation: 'widgetTranslation/updateWidgetTranslation'
    }),
    loadTranslation() {
      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code !== 'en' && this.selectedWidgetTranslation) {
        this.tWebConnectVegaText = this.selectedWidgetTranslation.vegaText
        this.tWebConnectVegaMessage = this.selectedWidgetTranslation.vegaMessage
      }
    },
    async getTranslation() {
      this.setSelectedLanguageForWebConnect(this.selectedLanguage)

      const languageRef = await this.$db
        .collection('translations')
        .doc(this.activeUserInfo.company)
        .collection('web-connect')
        .doc(this.selectedVegaWidgetId)
        .collection('languages')
        .doc(this.selectedLanguage.code)
        .get()

      const translation = languageRef.data()

      if (translation && translation.webConnect) {
        this.setWidgetTranslation(translation.webConnect)
        this.setLoadedWidgetTranslation(Math.random().toString(36).substring(2, 15))
        this.setHasWidgetTranslationUpdated(false)
      } else {
        this.setDefaultWidgetTranslation(this.selectedLanguageForWebConnect.code)
        this.setLoadedWidgetTranslation(Math.random().toString(36).substring(2, 15))
        this.setHasWidgetTranslationUpdated(false)
      }
    },
    onTextChange() {
      this.popupMessage = this.$refs.quillEditorA.quill.root.innerHTML
    },
    onTextChangeTranslation() {
      this.tWebConnectVegaMessage = this.$refs.quillEditorATranslation.quill.root.innerHTML
    },
    async toggleActivateOnlyB2B() {
      const isActivateOnlyB2B = Boolean(!this.isActivateOnlyB2B)
      this.isActivateOnlyB2B = isActivateOnlyB2B

      try {
        await this.$vs.loading()
        await this.$db
          .collection('dialogs')
          .doc(this.selectedVegaWidgetId)
          .set({ vega: { b2bOnly: isActivateOnlyB2B } }, { merge: true })
        await this.$vs.loading.close()
      } catch (err) {
        //eslint-disable-next-line
        console.log(err)
      }
    },
    async toggleEnableVegaPopup() {
      const isVegaPopupEnable = Boolean(!this.isVegaPopupEnable)
      this.isVegaPopupEnable = isVegaPopupEnable

      try {
        await this.$vs.loading()
        await this.$db.collection('dialogs').doc(this.selectedVegaWidgetId).set({ vega: { isVegaPopupEnable } }, { merge: true })
        await this.$vs.loading.close()
      } catch (err) {
        //eslint-disable-next-line
        console.log(err)
      }
    },
    async toggleShowVegaOnlyWhenAgentsAreOnline() {
      const showVegaOnlyWhenAgentsOnline = Boolean(!this.showVegaOnlyWhenAgentsOnline)
      this.showVegaOnlyWhenAgentsOnline = showVegaOnlyWhenAgentsOnline

      try {
        await this.$vs.loading()
        await this.$db.collection('dialogs').doc(this.selectedVegaWidgetId).set({ vega: { showVegaOnlyWhenAgentsOnline } }, { merge: true })
        await this.$vs.loading.close()
      } catch (err) {
        //eslint-disable-next-line
        console.log(err)
      }
    }
  },
  watch: {
    async 'selectedLanguage.code'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getTranslation()
      }
    },
    hasLoadedWidgetTranslation(newVal) {
      if (newVal) {
        this.loadTranslation()
      }
      this.$nextTick(() => {
        this.errors.clear()
        this.$validator.reset()
      })
    },
    tWebConnectVegaText() {
      if (this.tWebConnectVegaText !== this.selectedWidgetTranslation.vegaText) {
        this.setHasWidgetTranslationUpdated(true)
        this.updateWidgetTranslation({ vegaText: this.tWebConnectVegaText })
      }
    },
    tWebConnectVegaMessage() {
      if (this.tWebConnectVegaMessage !== this.selectedWidgetTranslation.vegaMessage) {
        this.setHasWidgetTranslationUpdated(true)
        this.updateWidgetTranslation({ vegaMessage: this.tWebConnectVegaMessage })
      }
    },
    popupText() {
      this.setVegaPopupText(this.popupText)
    },
    popupMessage() {
      this.setVegaPopupMessage(this.popupMessage)
    },
    popupPosition() {
      this.setVegaPopupPosition(this.popupPosition)
    },
    vegaLockingTime() {
      this.setVegaLockingTime(this.vegaLockingTime)
    }
  }
}
</script>
<style lang="scss" scoped>
.pap-popup {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  margin-top: 10px;
  width: 88vw;
  // height: 90vh;
  // max-height: 835px;
  @media (min-width: 1200px) {
    width: calc(100vw - 410px) !important;
  }

  &-inputs {
    flex: 4;
    display: flex;
    flex-direction: column;
    margin-right: 100px;

    &--text {
      margin-top: 15px;
      &-input {
        padding: 10px;
        width: 100%;
        background: #ffffff;
        border: 1px solid #d9dbdd;
        border-radius: 8px;
      }
    }

    &--textarea {
      margin-top: 22px;

      .quill-editor {
        height: 150px;

        .ql-toolbar {
          max-height: 42px;
        }

        .ql-container {
          max-height: 108px;
          .ql-editor {
            font-family: 'Lato';
          }
        }
      }
    }
  }

  &-preview {
    flex: 5;
    display: flex;
    flex-direction: column;
  }

  &__preview-settings {
    &__info {
      position: relative;
      margin-right: 30px;
      padding: 20px 0 0 98px;

      &__action-img {
        position: absolute;
        top: 0;
        left: 20px;
      }

      &__title {
        color: rgb(66, 181, 255);
        font-size: 30px;
        font-weight: 700;
      }

      &__description {
        margin-top: 15px;
        max-width: 300px;
        color: rgb(69, 90, 100);
        font-size: 22px;
      }

      &__list {
        margin-top: 20px;

        &__item {
          position: relative;
          display: flex;
          align-items: center;
          color: rgb(69, 90, 100);
          font-weight: 700;

          &::before {
            content: '';
            display: block;
            margin-right: 9px;
            width: 17px;
            height: 17px;
            background-image: url('../../../assets/images/vega/blue-check-mark-icon.svg');
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.language-selection-select {
  .vs__dropdown-toggle {
    background-color: rgba(var(--vs-primary), 1) !important;
    cursor: pointer;
  }
  .vs__selected,
  .vs__search,
  .vs__actions {
    color: #fff !important;
  }
  font-size: 16px;
  margin-bottom: 5px;
}

.pap-popup-inputs--text {
  input:disabled {
    background-color: #eee;
  }
}

.pap-popup-inputs--textarea {
  .quill-editor {
    .ql-toolbar {
      max-height: 42px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    .ql-container {
      max-height: 108px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      .ql-editor {
        font-family: 'Lato';
      }
    }
    .ql-disabled {
      background-color: #eee;
    }
  }
}
</style>
